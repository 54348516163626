import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import heartIcon from '../assets/icon-heart.svg';
import gamepadIcon from '../assets/icon-gamepad.svg';
import workIcon from '../assets/icon-work.svg';
import lifeInContineroSmallImage from '../assets/life-continero-work-small.jpg';
import davidProfile from '../assets/people/davidFogl.jpeg';

import { Section } from './common';

import breakpoints from '../utils/breakpoints';
import WorkBenefitItem from './WorkBenefitItem';
import TitleHeader from './TitleHeader';
import { RoundBox } from './RoundBox';

const LifeInContinero = () => (
  <Section id="life-in-continero">
    <TitleHeader
      titleKey="LifeInContinero_title"
      descriptionKey="LifeInContinero_description"
    />
    <Wrapper>
      <div>
        <WorkBenefitItem
          icon={workIcon}
          blockOnMobile
          titleKey="WorkBenefit_flexibleWork_title"
          descriptionKey="WorkBenefit_flexibleWork_description"
        />

        <WorkBenefitItem
          icon={gamepadIcon}
          blockOnMobile
          titleKey="WorkBenefit_interestingProjects_title"
          descriptionKey="WorkBenefit_interestingProjects_description"
        />

        <WorkBenefitItem
          icon={heartIcon}
          blockOnMobile
          titleKey="WorkBenefit_team_title"
          descriptionKey="WorkBenefit_team_description"
        />
      </div>
      <ImageWrapper>
        <StyledRoundBox />
        <Image src={lifeInContineroSmallImage} loading="lazy" />
        <PersonQuoteCard>
          <FlexProfile>
            <ProfileImage src={davidProfile} />
            <div>
              <CeoTitle>
                <FormattedMessage id="CEO_name" />
              </CeoTitle>
              <div>
                <FormattedMessage id="CEO_position" />
              </div>
            </div>
          </FlexProfile>
          <Quote>
            <FormattedMessage id="CEO_quote" />
          </Quote>
        </PersonQuoteCard>
      </ImageWrapper>
    </Wrapper>
  </Section>
);

export default LifeInContinero;

const CeoTitle = styled.div`
  font-weight: bold;
`;

const StyledRoundBox = styled(RoundBox)`
  border-radius: 80px;
  width: 400px;
  height: 500px;
  top: -200px;
  right: -120px;
  background: #fffaed;
`;

const Quote = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  position: relative;
`;

const Image = styled.img`
  width: 500px;
  z-index: 1;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 64px;
  border-radius: 50%;
  margin-right: 16px;
  position: absolute;
  left: -70px;
`;

const FlexProfile = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: none;

  @media screen and (min-width: 1300px) {
    display: block;
  }
`;

const PersonQuoteCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 7px 24px rgba(194, 209, 217, 0.5);
  border-radius: 30px;
  padding: 24px;
  position: absolute;
  z-index: 2;
  bottom: -64px;
  padding-left: 48px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${breakpoints.tablet} {
    align-items: center;
    flex-direction: row;
    text-align: left;

    & > *:not(:last-child) {
      margin-right: 64px;
      margin-bottom: 0;
    }
  }
`;
