import React from 'react';
import { ThemeProvider } from 'styled-components';
import Loadable from '@loadable/component';

import Content from '../components/Content';
import { lightTheme } from '../theme';
import GlobalStyles from '../GlobalStyles';
import Navbar from '../components/Navbar';

import Helmet from '../components/Layout';
import Footer from '../components/Footer';
import OurServices from '../components/OurServices';
import LifeInContinero from '../components/LifeInConfinero';
import OurProducts from '../components/OurProducts';
import Technologies from '../components/Technologies';
// import BlogSection from '../components/BlogSection';
// import Reviews from '../components/Reviews';
import UpArrow from '../components/UpArrow';
import PeopleSection from '../components/PeopleSection';
import TeamGallery from '../components/TeamGallery';
import ContactUs from '../components/ContactUs';


const InstagramSection = Loadable(() =>
  import('../components/InstagramSection')
);

const IndexPage = () => (
  <ThemeProvider theme={lightTheme}>
    <Helmet />
    <GlobalStyles />
    <>
      <Navbar />
      <Content />
      <OurServices />
      <OurProducts />
      <Technologies />
      <LifeInContinero />
      <PeopleSection />
      <TeamGallery />

      {/* <Reviews />
      <BlogSection /> */}
      <ContactUs />
      <InstagramSection />
      <Footer />
    </>
  </ThemeProvider>
);

export default IndexPage;
