import React from 'react';
import styled from 'styled-components';

import linkedInButton from '../assets/linkedin_btn.svg';
import { NewTabLink } from './common';

const Person = ({
  title,
  position,
  description,
  imageUrl,
  linkedInUrl,
  ...other
}) => (
  <AboutMeSection {...other}>
    <div>
      <ProfileWrapper>
        <NewTabLink href={linkedInUrl}>
          <ProfileImage src={imageUrl} />
          {linkedInUrl && (
            <LinkedInWrapper>
              <img src={linkedInButton} alt="linkedin" />
            </LinkedInWrapper>
          )}
        </NewTabLink>
      </ProfileWrapper>
      <NewTabLink href={linkedInUrl}>
        <Title>{title}</Title>
      </NewTabLink>
      <Subtitle>{position}</Subtitle>
      <Description>{description}</Description>
    </div>
  </AboutMeSection>
);

export default Person;

const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 80px;
  margin: 0 auto;
`;

const LinkedInWrapper = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: -8px;
  right: 0;
  cursor: pointer;
`;

const AboutMeSection = styled.div`
  text-align: center;
  padding: 0 16px;
`;

const ProfileImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 84%;
  margin-bottom: 8px;
  font-weight: bold;
  margin-top: 16px;
`;

const Subtitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;
const Description = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-top: 16px;
`;
