import React from 'react';
import styled from 'styled-components';
import technologyList from '../assets/techology-list.jpg';

import { Section } from './common';

import TitleHeader from './TitleHeader';

const Technologies = () => (
  <Section id="technologies">
    <TitleHeader
      titleKey="Technology_title"
      descriptionKey="Technology_description"
      center
    />
    <ImgWrapper>
      <img src={technologyList} alt="image" />
    </ImgWrapper>
  </Section>
);

export default Technologies;

const ImgWrapper = styled.div`
  text-align: center;
  margin-top: 54px;
  img {
    max-width: 1000px;
    width: 100%;
  }
`;
