import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

import { Section } from './common';
import Person from './Person';
import JoinUsBox from './JoinUsBox';
import breakpoints from '../utils/breakpoints';
import peopleCs from '../data/people';
import peopleEn from '../data/people-en';

const PeopleSection = () => {
  const intl = useIntl();
  const { locale } = intl;

  const people = locale === 'cs' ? peopleCs : peopleEn;

  return (
    <StyledSection id="our-team">
      <AboutMeSection>
        <Person {...people[0]} />
        <Person {...people[1]} />
        <Person {...people[2]} />
        <Person {...people[3]} />
      </AboutMeSection>
      <Bottom>
        <Flex>
          <Person {...people[4]} />
          <Person {...people[5]} />
        </Flex>
        <JoinUsBox />
      </Bottom>
    </StyledSection>
  );
};

export default PeopleSection;

const StyledSection = styled(Section)`
  max-width: 1200px;
`;

const Flex = styled.div`
  display: none;
  width: 50%;

  ${breakpoints.desktop} {
    display: flex;

    & > * {
      width: 50%;
    }
  }
`;

const Bottom = styled.div`
  margin-bottom: 64px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > *:last-child {
    width: 100%;
  }

  ${breakpoints.desktop} {
    & > *:last-child {
      width: 50%;
    }
  }
`;

const AboutMeSection = styled.div`
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 100%;
    margin-bottom: 16px;
  }

  ${breakpoints.mobile} {
    & > div {
      width: 50%;
    }
  }

  ${breakpoints.tablet} {
    & > div {
      width: 25%;
    }
  }
`;
