import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import { Section, Button } from './common';
import heroImage from '../assets/hero-images.png';

import breakpoints from '../utils/breakpoints';
import { RoundBox } from './RoundBox';
import safeScrollTo from '../utils/safeScrollTo';

const Content = () => {
  const intl = useIntl();
  const { locale } = intl;

  const EN_HEADER = (
    <div>
      Modern way of <Bold>development</Bold> and software <Bold>testing</Bold>
    </div>
  );

  const CS_HEADER = (
    <div>
      Moderní přístup k <Bold>vývoji</Bold> a <Bold>testovaní</Bold> software
    </div>
  );

  const header = locale === 'cs' ? CS_HEADER : EN_HEADER;

  return (
    <StyledSection id="what-we-do">
      <FirstRoundBox />
      <SecondRoundBox />
      <Wrapper>
        <div>
          <Header>{header}</Header>
          <Description>
            <FormattedMessage id="homepage_description" />
          </Description>
          <Button onClick={() => safeScrollTo('#our-services')}>
            <FormattedMessage id="HowCanWeHelp" />
          </Button>
          {/* <OurClietsWrapper>
            <OurClientTitle onClick={() => safeScrollTo('#reviews')}>
              <FormattedMessage id="OurClients" /> →
            </OurClientTitle>
          </OurClietsWrapper> */}
        </div>
        <LeftSide>
          <HeroImageWrapper>
            <HeroImage alt="companies" src={heroImage} loading="lazy" />
            <HeroRoundBox />
          </HeroImageWrapper>
        </LeftSide>
      </Wrapper>
    </StyledSection>
  );
};

export default Content;

const StyledSection = styled(Section)`
  position: relative;
`;

const HeroImageWrapper = styled.div`
  position: relative;
`;

const FirstRoundBox = styled(RoundBox)`
  width: 375px;
  height: 300px;
  top: -250px;
  left: 400px;

  background: linear-gradient(
    134.58deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 90px;
`;

const SecondRoundBox = styled(RoundBox)`
  width: 475px;
  height: 482px;
  top: 600px;
  left: -500px;

  background: linear-gradient(
    134.58deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 90px;
`;

const HeroRoundBox = styled(RoundBox)`
  position: absolute;
  width: 400px;
  height: 550px;
  background: #f5f6ff;
  border-radius: 100px;
  z-index: 1;
  top: 100px;
  right: 80px;
`;

const LeftSide = styled.div`
  display: none;
  width: 100%;

  ${breakpoints.desktop} {
    display: block;
  }
`;

const HeroImage = styled.img`
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  object-fit: contain;
  z-index: 200;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  text-align: center;

  align-items: center;
  flex-direction: row;

  ${breakpoints.desktop} {
    text-align: left;
    & > div {
      width: 50%;
    }
  }
`;

const Header = styled.div`
  font-size: 42px;
  line-height: 45px;

  ${breakpoints.mobile} {
    font-size: 50px;
    line-height: 60px;
  }

  ${breakpoints.tablet} {
    line-height: 80px;
    font-size: 73px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 26px;
  margin: 24px 0;

  ${breakpoints.tablet} {
    font-size: 24px;
    line-height: 32px;
    margin: 32px 0;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;
