import React from 'react';
import styled from 'styled-components';

import { Section } from './common';
import ourProducts from '../assets/our-products.jpg';

import breakpoints from '../utils/breakpoints';
import CardItem from './CardItem';
import TitleHeader from './TitleHeader';
import { RoundBox } from './RoundBox';

const items = [
  {
    titleKey: 'OurProduct_1_title',
    descriptionKey: 'OurProduct_1_description',
  },
  {
    titleKey: 'OurProduct_2_title',
    descriptionKey: 'OurProduct_2_description',
  },
  {
    titleKey: 'OurProduct_3_title',
    descriptionKey: 'OurProduct_3_description',
  },
];

const OurProducts = () => (
  <Section id="our-products">
    <Wrapper>
      <LeftSide>
        <TitleHeader
          titleKey="OurProducs"
          descriptionKey="OurProducs_description"
        />
        <CardItemWrapper>
          {items.map(item => (
            <CardItem {...item} key={item.titleKey} />
          ))}
        </CardItemWrapper>
      </LeftSide>
      <HeroImageWrapper>
        <Image src={ourProducts} alt="products" loading="lazy" />
        <StyledRoundBox />
      </HeroImageWrapper>
    </Wrapper>
  </Section>
);

export default OurProducts;

const CardItemWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const HeroImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: relative;
  z-index: 1;
`;

const StyledRoundBox = styled(RoundBox)`
  background: #f5f6ff;
  border-radius: 100px;
  height: 470px;
  width: 600px;

  top: -60px;
  right: -300px;
`;

const LeftSide = styled.div`
  max-width: 600px;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 90%;
  }

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }

  ${breakpoints.tablet} {
    align-items: center;
    flex-direction: row;
    text-align: left;

    & > *:first-child {
      width: 60%;
    }

    & > *:last-child {
      width: 40%;
    }

    & > *:not(:last-child) {
      margin-right: 64px;
      margin-bottom: 0;
    }
  }
`;
