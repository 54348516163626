import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { Button } from './common';
import Person from './Person';
import breakpoints from '../utils/breakpoints';
import rockstarProfile from '../assets/rockstar_profile.svg';

const JoinUsBox = props => (
  <AboutMeSection {...props}>
    <Person
      title={<FormattedMessage id="Career_Rockstar_title" />}
      imageUrl={rockstarProfile}
      position={<FormattedMessage id="Career_Rockstar_position" />}
      description={<FormattedMessage id="Career_Rockstar_description" />}
    />
    <JoinUsWrapper>
      <JoinUsTitle>
        <FormattedMessage id="JoinUsBox_title" />
      </JoinUsTitle>
      <JoinUsSubtitle>
        <FormattedMessage id="JoinUsBox_subtitle" />
      </JoinUsSubtitle>
      <Link to="/career#job-list">
        <ButtonElement>
          <FormattedMessage id="JoinUsBox_description" />
        </ButtonElement>
      </Link>
    </JoinUsWrapper>
  </AboutMeSection>
);

export default JoinUsBox;

const JoinUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AboutMeSection = styled.div`
  width: 100%;
  background: #fffaed;
  border-radius: 40px;

  padding: 32px;
  text-align: center;

  ${breakpoints.mobile} {
    display: flex;
    justify-content: space-around;
    text-align: left;
  }

  ${breakpoints.tablet} {
    margin: -32px 0;
  }
`;

const JoinUsTitle = styled.div`
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
`;

const JoinUsSubtitle = styled.div`
  font-size: 16px;
  line-height: 20px;

  margin-top: 8px;
  margin-bottom: 32px;

  ${breakpoints.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const ButtonElement = styled(Button)`
  font-size: 16px;
  line-height: 24px;
`;
