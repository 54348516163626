import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import emailjs from 'emailjs-com';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import message from 'antd/lib/message';

import { Section, Button, NewTabLink } from './common';
import breakpoints from '../utils/breakpoints';
import phoneIcon from '../assets/icon/icon-phone.svg';
import emailIcon from '../assets/icon/icon-email.svg';
import calendlyLogo from '../assets/calendly_logo.svg';
import GoogleAddressMap from './GoogleAddressMap';
import TitleHeader from './TitleHeader';

const ContactUs = props => {
  const { formatMessage } = useIntl();

  const submit = values => {
    emailjs
      .send('service_lr81272', 'template_r4ip70o', values, 'XpMXSwC63D6NCnsdK')
      .then(
        () => {
          message.success(formatMessage({ id: 'EmailSent' }));
        },
        error => {
          console.log(error.text);
          message.error(formatMessage({ id: 'EmailSubmitError' }));
        }
      );
  };

  const initialValues = {
    name: '',
    email: '',
    text: formatMessage({ id: 'ContentEmailTemplate' }),
    terms: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      formatMessage({ id: 'EmailAllFieldsAreRequired' })
    ),
    text: Yup.string().required(
      formatMessage({ id: 'EmailAllFieldsAreRequired' })
    ),
    terms: Yup.bool().oneOf(
      [true],
      formatMessage({ id: 'TermsNeedAgreeCheck' })
    ),

    email: Yup.string()
      .email('EmailNotCorrentFormat')
      .required(formatMessage({ id: 'EmailAllFieldsAreRequired' })),
  });

  return (
    <Section id="contact-us">
      <Wrapper {...props}>
        <StyledGoogleMap />
        <div>
          <TitleHeader titleKey="ContactUs" />
          <div>
            <CompanyTitle>Continero Corp s.r.o</CompanyTitle>
            <Address>
              <div>třída Kpt. Jaroše 1922/3</div>
              <div>602 00, Brno</div>
              <div>Czechia</div>
            </Address>
            <PhoneAndEmailWrapper>
              <IconAndTextWrapper>
                <img src={phoneIcon} />{' '}
                <span>
                  <NewTabLink href="tel:+420605375252">
                    +420 605 375 252
                  </NewTabLink>
                </span>
              </IconAndTextWrapper>
              <IconAndTextWrapper>
                <img src={emailIcon} />{' '}
                <span>
                  <NewTabLink href="mailto:info@continero.com">
                    info@continero.com
                  </NewTabLink>
                </span>
              </IconAndTextWrapper>
            </PhoneAndEmailWrapper>
            <NewTabLink href="https://calendly.com/david-fogl">
              <Button backgroundColor="#48A1F8" leftIcon={calendlyLogo}>
                <FormattedMessage id="CalendlyBookButton" />
              </Button>
            </NewTabLink>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
          >
            {({ errors }) => (
              <StyledForm>
                <FormTitle>
                  <FormattedMessage id="ContactUs_form_title" />
                </FormTitle>
                <label htmlFor="">
                  <FormattedMessage id="NameSurname" /> *
                </label>
                <InputField id="name" name="name" placeholder="Jane" />
                <label htmlFor="email">
                  <FormattedMessage id="Email" /> *
                </label>
                <InputField
                  id="email"
                  name="email"
                  placeholder="jan@acme.com"
                  type="email"
                />
                <label htmlFor="text">
                  <FormattedMessage id="HowCanWeHelp" /> *
                </label>
                <InputField
                  id="text"
                  name="text"
                  rows="4"
                  component="textarea"
                />

                <CheckboxLabel>
                  <Field type="checkbox" name="terms" />
                  <FormattedMessage id="TermsAgree" />
                </CheckboxLabel>
                <Button type="submit">
                  <FormattedMessage id="SendMessageButton" />
                </Button>

                <ErrorMessage>
                  {errors.email || errors.name || errors.text || errors.terms}
                </ErrorMessage>
              </StyledForm>
            )}
          </Formik>
        </div>
      </Wrapper>
    </Section>
  );
};

export default ContactUs;

const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
  input {
    margin-right: 8px;
  }
`;

const StyledForm = styled(Form)`
  margin-top: 48px;
  max-width: 340px;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const FormTitle = styled.div`
  font-size: 20px;
  line-height: 84%;
  font-weight: bold;
`;

const InputField = styled(Field)`
  width: 308px;
  padding: 8px 16px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c2d1d9;
  box-sizing: border-box;
  border-radius: 7px;
`;

const CompanyTitle = styled.div`
  font-size: 21px;
  line-height: 25px;
  margin-top: 32px;
  margin-bottom: 8px;
`;

const Address = styled.div`
  * {
    font-size: 14px;
    color: #8698a4;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${breakpoints.tablet} {
    align-items: center;
    flex-direction: row;
    text-align: left;

    & > div {
      width: 50%;
    }

    & > *:not(:last-child) {
      margin-right: 64px;
      margin-bottom: 0;
    }
  }

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const StyledGoogleMap = styled(GoogleAddressMap)`
  display: none;

  ${breakpoints.tablet} {
    display: block;
  }
`;

const PhoneAndEmailWrapper = styled.div`
  padding: 16px 0;
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;
  ${breakpoints.tablet} {
    display: block;
    justify-content: left;
  }

  img {
    margin-right: 8px;
  }
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  font-weight: bold;
`;
