import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const MAP_KEY = 'AIzaSyCKmenXbGf3O9qBfzxiADUsKn-oJjyamDk';

const AnyReactComponent = () => (
  <div
    style={{
      color: 'white',
      background: 'grey',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      height: 60,
      width: 60,
    }}
  >
    Continero
  </div>
);

const position = {
  lat: 49.2004161,
  lng: 16.6084308,
};

class GoogleAddressMap extends Component {
  static defaultProps = {
    center: position,
    zoom: 14,
  };

  render() {
    return (
      <div
        style={{
          height: 700,
          borderRadius: '50px',
          overflow: 'hidden',
        }}
        {...this.props}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAP_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent {...position} text="My Marker" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleAddressMap;
