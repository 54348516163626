import React from 'react';
import styled from 'styled-components';
import ourServicesImage from '../assets/our-services.jpg';

import { Section } from './common';

import breakpoints from '../utils/breakpoints';
import CardItem from './CardItem';
import TitleHeader from './TitleHeader';
import { RoundBox } from './RoundBox';

const items = [
  {
    titleKey: 'OurService_1_title',
    descriptionKey: 'OurService_1_description',
  },
  {
    titleKey: 'OurService_2_title',
    descriptionKey: 'OurService_2_description',
  },
  {
    titleKey: 'OurService_3_title',
    descriptionKey: 'OurService_3_description',
  },
];

const OurServices = () => (
  <Section id="our-services">
    <Wrapper>
      <HeroImageWrapper>
        <Image src={ourServicesImage} alt="services" />
        <StyledRoundBox />
      </HeroImageWrapper>
      <LeftSide>
        <TitleHeader
          titleKey="OurServices"
          descriptionKey="OurServices_description"
        />

        <CardItemWrapper>
          {items.map(item => (
            <CardItem {...item} key={item.titleKey} />
          ))}
        </CardItemWrapper>
      </LeftSide>
    </Wrapper>
  </Section>
);

export default OurServices;

const HeroImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: relative;
  z-index: 1;
`;

const StyledRoundBox = styled(RoundBox)`
  background: #f5f6ff;
  border-radius: 100px;
  right: 30px;
  top: 100px;
  height: 470px;
  width: 600px;
`;

const CardItemWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const LeftSide = styled.div`
  max-width: 600px;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: 90%;
  }

  ${breakpoints.tablet} {
    align-items: center;
    flex-direction: row;
    text-align: left;

    & > *:not(:last-child) {
      margin-right: 64px;
      margin-bottom: 0;
    }

    & > *:first-child {
      width: 40%;
    }

    & > *:last-child {
      width: 60%;
    }
  }

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`;
